import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConstants } from 'app/app-constants';
import { ConfigComponent } from 'app/core/config/config';
import { Injectable } from '@angular/core';
import { MenuesServices } from 'app/core/interfaces/menues.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';

@Injectable()
export class MenuesService implements MenuesServices{
    constructor(
        public httpClient: HttpClient,
        private readonly config: ConfigComponent)
    { }

    // Variable que almacena la url de los webservices
    private urlOmt = this.config.getUrlOmt();

    /**
     * Metodo encargado de solicitar al web service el menu principal del
     * restaurante especificado
     * @param idRestaurant: string -> Identificador del restaurante
     * json {idRestaurant: string, isExternalMenu: boolean = false, idService: string = null}
     */
    /**
     * Se realiza correctamente el tipado del request y del response en la petición del servicio para
     * obtener el menú de un respectivo restaurante
     */
    public getMainMenu(data): Observable<ResponsesStructure> {
        const params = new HttpParams();
        let url = `${this.urlOmt}restaurant/${data.idRestaurant}/menu?new=false&brand=${AppConstants.BRAND_ALIAS.name}&servicesType=${data.idService}`;
        if (data.externalMenu) {
            url = `${this.urlOmt}restaurant/${data.idRestaurant}/menu?new=false&brand=${AppConstants.BRAND_ALIAS_ONLINE.name}&servicesType=${data.idService}`;
        }
        return this.httpClient.get<ResponsesStructure>(url, { params}).pipe(
            map((response: any) => {
              return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Metodo encargado de solicitar al web service las categorias pertenecientes
     * al menu del restaurante especificado
     * @param idRestaurant: number -> Identificador del restaurante
     * @param idMenu: number -> Identificador del menu del restaurante
     * @param userLang: string -> Idioma que maneja la aplicacion
     */
    /**
     * Se realiza el tipado del response de la petición del servicio para obtener todas las 
     * categorías pero se evidencia que este servicio no se llama en ningún componente, por ende, 
     * no se pudieron realizar pruebas de aceptación
     */
    public getAllCategories(idRestaurant: string, idMenu: number): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}/${idRestaurant}/menu/${idMenu}/categories`;
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Método encargado de solicitar al web service los productos de una categoría especìfica
     * @param idCategory: string -> Es el identificador de la categoría que se desea consultar sus productos
     */
    /**
     * Se realiza el respectivo tipado del response de la petición que retorna los productos de una 
     * categoría específica
     */
    public getProducts(idCategory: string, isExternalMenu: boolean = false, idService: string = null): Observable<ResponsesStructure> {
        let url = `${this.urlOmt}category/${idCategory}/products?brand=${AppConstants.BRAND_ALIAS.name}&servicesType=${idService}`;
        if (isExternalMenu) {
            url = `${this.urlOmt}category/${idCategory}/products?brand=${AppConstants.BRAND_ALIAS_ONLINE.name}&servicesType=${idService}`;
        }
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Metodo encargado de solicitar al web service los grupos modificadores y productos modificadores asociados a un producto especifico
     * @param idCategory: string -> Es el id de la categoria asociada al producto que se esta agregando a la compra
     * @param idProduct: string -> Es el id del producto que se esta agregando a la compra
     */
    /**
     * Se realiza el respectivo tipado en el response de la petición del servicio que retorna
     * toda la información de los modificadores de los productos actuales
     */
    // public getModifierProducts(idCategory: string, idProduct: string, isSubMod: boolean = false): Observable<ResponsesStructure> {
    //     const url = `${this.urlOmt}category/${idCategory}/products-modifiers/${idProduct}?isSubmodifier=${isSubMod}`;
    //     return this.httpClient.get<ResponsesStructure>(url).pipe(
    //         map((response: ResponsesStructure) => {
    //             return response;
    //         }),
    //         catchError(e => {
    //           return throwError(e);
    //         })
    //     );
    // }


    /**
     * Metodo encargado de solicitar al web service los grupos modificadores y productos modificadores asociados a un producto especifico
     */
    public getModifier(idCategory: string, idProduct: string, idservicetype: string, isSubMod: boolean = false, isExternalMenu: boolean = false,) {
        let url = `${this.urlOmt}category/${idCategory}/products-modifiers/${idProduct}/${idservicetype}/${AppConstants.BRAND_ALIAS.name}?isSubmodifier=${isSubMod}`;
        if(isExternalMenu){
            url = `${this.urlOmt}category/${idCategory}/products-modifiers/${idProduct}/${idservicetype}/${AppConstants.BRAND_ALIAS_ONLINE.name}?isSubmodifier=${isSubMod}`;
        }
        return this.httpClient.get<ResponsesStructure>(url).pipe(map((response: ResponsesStructure) => {
            return response;
        }),
            catchError(e => {
                return throwError(e);
            })
        );
    }

    /**
     * Método encargado de consultar los servicios disponibles según el restaurante (pickup, delivery y dine in)
     * @param idRestaurant id del restaurante
     * @author Alvaro Felipe Garcia Mendez - Jan 16-2019
     * @version 1.0.0
     */
    // public getServicesAndBrands(idRestaurant: string): any {
    //     const url = `${this.urlOmt}restaurant/verifyservicesandbrand/${idRestaurant}`;
    //     return this.httpClient.get(url);
    // }


    /**
     * Método encargado de solicitar al web service los productos que esten buscando en el buscador
     * @param nameProduct: string -> Es el identificador de la categoría que se desea consultar sus productos
     */
    /**
     * Se realizó el respectivo tipado del response de la petición del servicio que retorna los
     * productos que cumplan con el filtro enviado
     */
    public getProducstFromFinder(nameProduct: string, idRestaurant: string, idService: string): Observable<ResponsesStructure> {
        const url = this.urlOmt + 'restaurant/' + idRestaurant +
            '/search_product?brand=' + AppConstants.BRAND_ALIAS.name + '&servicesType=' + idService + '&nameproduct=' + nameProduct;
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }
}
